import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import {
  useBbox,
  useComponentProps,
  useEffectEvent,
  useEffectItem,
  useMergeFieldData,
  useUpdatedRef
} from 'helpers/hooks/utils';
import ProfileCard from 'components/organisms/Cards/ProfileCard/ProfileCard';
import utils from 'helpers/utils';
import StyledProfile from 'components/organisms/Profiles/Profile/Profile.styles';
import CardItem from 'components/atoms/Cards/Carditem/CardItem';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import useMediaQuery from '@mui/material/useMediaQuery';
import constants from 'helpers/constants';
import dom from 'helpers/dom';

const Profile = React.forwardRef((props, ref) => {
  const {
    cards,
    data,
    fieldData,
    editInline,
    onShowCard,
    onDirty,
    onCloseCard,
    CardComponent,
    ProfileCardProps,
    ...innerProps
  } = useComponentProps(props, 'Profile', {
    styled: ['color'],
    static: ['isEditing'],
    children: ['card']
  });

  const profileProvider = useProfile();
  const profileData = profileProvider.data?.data;
  const activeCard = profileProvider.state?.settings?.activeCard;
  const hideProfile = profileProvider.state?.settings?.hideProfile;
  const fieldDataMemo = useMergeFieldData(profileProvider.fieldData, fieldData);

  const innerRef = useRef(null);
  const cardRefs = useRef({});
  const submitRef = useRef(false);
  const initialised = useRef(null);
  const [internalState, setInternalState] = useState({});

  const dataMemo = useEffectItem(data ?? profileData);

  const onDirtyEvent = useEffectEvent(onDirty);
  const dirtyEvent = useEffectEvent(profileProvider.dirty);
  const successEvent = useEffectEvent(profileProvider.success);
  const errorEvent = useEffectEvent(profileProvider.error);
  const validationEvent = useEffectEvent(profileProvider.validation);

  const doReset = useCallback(() => {
    Object.keys(cardRefs.current).forEach((k) => {
      const card = cardRefs.current[k];
      card.ref?.resetForm?.();
    });
    onDirtyEvent?.(false);
    dirtyEvent?.(false);
  }, [onDirtyEvent, dirtyEvent]);

  const doSetSubmitting = useCallback((value) => {
    Object.keys(cardRefs.current).forEach((k) => {
      const card = cardRefs.current[k];
      card.ref?.setSubmitting?.(value);
    });
    onDirtyEvent?.(false);
    dirtyEvent?.(false);
  }, [onDirtyEvent, dirtyEvent]);

  const doSubmit = useCallback(() => {
    Promise.all(Object.keys(cardRefs.current).map((k) => {
      const card = cardRefs.current[k];
      return card.ref?.submit?.(true).then((res) => {
        return {card: k, ...res};
      });
    })).then((submissions) => {
      const error = submissions.find((submission) => submission?.error);
      if (error) {
        if (error.card && error.error?.length > 0) {
          const el = innerRef.current?.refs?.ref?.current?.querySelector(`.Profile-card-${error.card.split('_')[1]} .FormField-name-${Object.keys(error.error[0])[0]}`);
          dom.scrollIntoView(el, {align: 'center', anchor: 'top', preventScroll: false});
        }

        errorEvent?.('Please check if all fields have the correct values');
      } else if (!submissions.some((submission) => submission?.submitting)) {
        successEvent?.('Saved');
        onDirtyEvent?.(false);
        dirtyEvent?.(false);
      } else {
        submitRef.current = true;
        Object.keys(cardRefs.current).map((k) => {
          const card = cardRefs.current[k];
          card.submitting = Boolean(submissions.find((submission) => submission?.card === k && submission?.submitting));
          card.submission = null;
          return card.ref?.submit?.(false);
        });
      }
    })
  }, [successEvent, errorEvent, dirtyEvent, onDirtyEvent]);

  const profile = useMemo(() => {
    const state = {
      ...internalState,
      ...profileProvider.state
    };

    return {
      refs: {
        ref: innerRef,
        cardRefs
      },
      state,
      cards,
      data: dataMemo,
      initialised: () => {
        return initialised.current === true;
      },
      toggle: () => {
        setInternalState((current) => ({...current, isEditing: !current.isEditing}));
      },
      reset: () => {
        doReset();
      },
      setSubmitting: (value) => {
        doSetSubmitting(value);
      },
      submit: () => {
        doSubmit();
      }
    }
  }, [internalState, cards, dataMemo, profileProvider.state, doSubmit, doReset, doSetSubmitting]);

  const profileRef = useUpdatedRef(profile);

  useImperativeHandle(ref, () => profile);

  const submitEvent = useEffectEvent(profileProvider.submit)
  const handleBeforeSubmit = useCallback((submitCard) => {
    if (profileRef.current.state.isEditing && !submitRef.current) {
      if (submitEvent) {
        submitEvent?.();
      } else {
        doSubmit();
      }
    } else {
      submitCard();
    }
  }, [profileRef, doSubmit, submitEvent]);

  const handlePatch = useCallback((id, onPatch) => (field, value, onSuccess, onError) => {
    onPatch?.(profileRef.current.data, field, value, onSuccess, onError);
  }, [profileRef]);

  const handleSubmit = useCallback((id, onSubmit) => (values, fields, actions, onSuccess, onError) => {
    const cards = cardRefs.current;

    cards[id] = {
      ...cards[id],
      submission: {
        values,
        fields,
        actions,
        onSuccess,
        onError
      }
    }

    const allSubmitted = !Object.keys(cards).some((k) => {
      const c = cards[k];
      return c.submitting && !c.submission;
    });

    if (allSubmitted) {
      const values = Object.keys(cards).reduce((o, k) => {
        const c = cards[k];
        return {...o, ...c.submission?.values};
      }, {});

      const fields = Object.keys(cards).reduce((o, k) => {
        const c = cards[k];
        return [...o, ...(c.submission?.fields ?? [])];
      }, []);

      onSubmit?.(
        profileRef.current.data,
        values,
        fields,
        {
          setSubmitting: profileRef.current.setSubmitting,
          resetForm: profileRef.current.resetForm
        },
        (msg) => {
          Object.keys(cards).forEach((k) => {
            const c = cards[k];
            if (c.submission?.onSuccess) {
              c.submission.onSuccess(msg);
            }
          }, {});
        },
        (err) => {
          Object.keys(cards).forEach((k) => {
            const c = cards[k];
            if (c.submission?.onError) {
              c.submission.onError(err);
            }
          }, {});
        }
      );
    }
  }, [profileRef]);

  const handleAfterSubmit = useCallback((id) => (success, error) => {
    const cards = cardRefs.current;
    cards[id] = {
      ...cards[id],
      submission: {
        success: success,
        error: error
      }
    }

    const allSubmitted = !Object.keys(cards).some((k) => {
      const c = cards[k];
      return c.submitting && !c.submission;
    });

    if (allSubmitted) {
      const hasError = Object.keys(cards).some((k) => {
        const c = cards[k];
        return Boolean(c.submission?.error);
      });

      // reset submission
      Object.keys(cards).forEach((k) => {
        const c = cards[k];
        c.submission = null;
        c.submitting = null;
        c.validation = hasError ? c.validation : null
      }, {});

      if (hasError) {
        submitRef.current = false;
        errorEvent?.('Saving failed');
      } else {
        submitRef.current = false;
        successEvent?.('Saved');
        onDirtyEvent?.(false);
        dirtyEvent?.(false);
      }
    }
  }, [successEvent, errorEvent, dirtyEvent, onDirtyEvent]);

  const handleValidating = useCallback((id) => (isDirty, hasErrors) => {
    const cards = cardRefs.current;

    cards[id] = {
      ...cards[id],
      validation: {
        isDirty,
        hasErrors
      }
    }

    const isSomeDirty = Object.keys(cards).some((k) => {
      const c = cards[k];
      return c.validation?.isDirty;
    });
    const hasSomeErrors = Object.keys(cards).some((k) => {
      const c = cards[k];
      return c.validation?.hasErrors;
    });
    if (hasSomeErrors) {
      validationEvent?.('Please check if all fields have the correct values');
    } else {
      validationEvent?.(null);
    }

    onDirtyEvent?.(isSomeDirty);
    dirtyEvent?.(isSomeDirty);
  }, [dirtyEvent, onDirtyEvent, validationEvent]);

  const onShowCardEvent = useEffectEvent(onShowCard);
  const cardsMemo = useMemo(() => {
    if (cards) {
      return cards
        .filter((card) => {
          return (!hideProfile || !activeCard || card.name === activeCard) && (onShowCardEvent ? onShowCardEvent?.(card) : true);
        })
        .sort((a, b) => a.position - b.position)
        .map((card, idx) => {
          const id = `key_${card.name ?? card.title ?? idx}`;

          card = {
            ...card,
            id: id,
            position: idx,
            isEditing: (activeCard === card.name) || card.isEditing,
            onBeforeSubmit: handleBeforeSubmit,
            onPatch: handlePatch(id, card.onPatch),
            onSubmit: handleSubmit(id, card.onSubmit),
            onAfterSubmit: handleAfterSubmit(id),
            onValidating: handleValidating(id)
          };

          return card;
        });
    } else {
      return null;
    }
  }, [cards, activeCard, hideProfile, handleValidating, handleBeforeSubmit, handlePatch, handleSubmit, handleAfterSubmit, onShowCardEvent]);

  const setProfileEvent = useEffectEvent(profileProvider.setProfile);
  useEffect(() => {
    setProfileEvent?.(profile);
  }, [setProfileEvent, profile]);

  useMemo(() => {
    if (cardsMemo || profile.state.isEditing) {
      clearTimeout(initialised.current);
      initialised.current = setTimeout(() => {
        initialised.current = true;
      }, constants.debounce.init)
    }
  }, [cardsMemo, profile.state.isEditing]);

  const handleCardRef = (id) => (ref) => {
    cardRefs.current[id] = {
      ...cardRefs.current[id],
      ref
    };
  }

  const handleCanDrag = (id) => {
    const card = cardsMemo?.find((c) => c.id === id);
    if (card) {
      return (innerProps.onCanDrag ? innerProps.onCanDrag(card) : card.editable) && card.draggable !== false;
    }
  }

  const handleDragStart = () => {
    setInternalState(utils.updater({isDragging: true}, true));
  }

  const handleDragStop = () => {
    setInternalState(utils.updater({isDragging: false}, true));
  }

  const handleDragDrop = (id, containerId, position) => {
    const card = cardsMemo?.find((c) => c.id === id);
    if (card) {
      card.onMove?.(dataMemo, card, containerId, position, null, () => {
        // error, reset cards
        innerRef.current?.reset?.();
      });
    }
  }

  const handleClose = (id) => () => {
    const card = cardsMemo.find((c) => c.id === id);
    if (card.name === activeCard) {
      profileProvider.setSettings({activeCard: null});
    }

    onCloseCard?.(card);
  }

  const bBox = useBbox(() => innerRef.current?.refs?.ref?.current, ['width']);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const columns = useMemo(() => {
    return utils.isDefined(innerProps.columns) ? innerProps.columns :
      (profile.state.isEditing ? 1 : (!lgUp || bBox?.width <= 640) ? 1 : 2)
  }, [bBox?.width, lgUp, innerProps.columns, profile.state.isEditing]);

  if (columns <= 1) {
    innerProps.variant = 'grid';
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    isEditing: profile.state.isEditing,
    isDragging: profile.state.isDragging,
    variant: innerProps.variant
  });

  return <StyledProfile ref={innerRef} {...innerProps}
                        columns={columns}
                        onCanDrag={handleCanDrag}
                        onDragStart={handleDragStart}
                        onDragDrop={handleDragDrop}
                        onDragStop={handleDragStop}>
    {(cardsMemo ?? [])
      .map((card) => {
        return <CardItem key={card.id}
                         data-key={card.id}
                         data-title={card.title}
                         data-droppable={card.droppable !== false}
                         fit={innerProps.variant === 'masonry'}
                         {...utils.filterObject(card, ['anchor', 'span', 'rows', 'grow', 'fit'], false)}>
          <CardComponent ref={handleCardRef(card.id)}
                         key={card.id}
                         className={`Profile-card Profile-card-${card.name}`}
                         data-profile-card-id={card.id}
                         profile={profile}
                         card={card}
                         onClose={handleClose(card.id)}
                         editInline={editInline || profile.state.isEditing}
                         fieldData={fieldDataMemo}
                         isLoading={profileProvider.isLoading()}
                         {...ProfileCardProps}/>
        </CardItem>
      })}
  </StyledProfile>
});

Profile.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  cards: PropTypes.array,
  data: PropTypes.object,
  editInline: PropTypes.bool,
  color: PropTypes.string,
  onDirty: PropTypes.func,
  onCloseCard: PropTypes.func,
  CardComponent: PropTypes.any,
  ProfileCardProps: PropTypes.object
};

Profile.defaultProps = {
  orientation: 'vertical',
  variant: 'masonry',
  editInline: false,
  color: 'primary',
  gap: 16,
  debounce: false,
  CardComponent: ProfileCard
};

export default Profile;
