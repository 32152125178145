import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledEntityProfile from 'components/organisms/Profiles/EntityProfile/EntityProfile.styles';
import utils from 'helpers/utils';
import {useAuthClient, useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import profile from 'helpers/profile';
import EntityFinanceHistoryProfileCardContent
  from 'components/organisms/Cards/EntityFinanceHistoryProfileCardContent/EntityFinanceHistoryProfileCardContent';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import constants from 'helpers/constants';
import {useAuthIsVentureIq} from 'services/auth/auth.utils';
import EntityScoreGraphProfileCardContent
  from 'components/organisms/Cards/EntityScoreGraphProfileCardContent/EntityScoreGraphProfileCardContent';
import {useCollectionGet} from 'services/collection/collection.hooks';
import {
  processEntityFeedback,
  processEntityInfo,
  processEntityPoints
} from 'services/entity/entity.utils';
import Typography from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import EntityTractionGraphProfileCardContent
  from 'components/organisms/Cards/EntityTractionGraphProfileCardContent/EntityTractionGraphProfileCardContent';
import EntitySystemInfoProfileCardContent
  from 'components/organisms/Cards/EntitySystemInfoProfileCardContent/EntitySystemInfoProfileCardContent';
import logger from 'helpers/logger';
import EntityPatentGraphProfileCardContent
  from 'components/organisms/Cards/EntityPatentGraphProfileCardContent/EntityPatentGraphProfileCardContent';
import EntityArticlesProfileCardContent
  from 'components/organisms/Cards/EntityArticlesProfileCardContent/EntityArticlesProfileCardContent';

const EntityProfile = (props) => {
  const {
    cards,
    onCanUpdate,
    ...innerProps
  } = useComponentProps(props, 'EntityProfile');

  const updateRef = useRef(null);
  const enrichRef = useRef(null);
  const profileProvider = useProfile();
  const context = profileProvider.context?.data;
  const cardDefinitions = profileProvider.cardDefinitions;
  const activeCard = profileProvider.state?.settings?.activeCard;

  const isVentureIq = useAuthIsVentureIq();
  const client = useAuthClient();
  const universeCollection = useCollectionGet({collectionId: client?.universeCollectionId},
    {enabled: Boolean(client?.universeCollectionId)})?.data;
  const isUniverse = Boolean(context?.universeCollectionId === universeCollection?.collectionId);
  const collection = isUniverse ? universeCollection : context;

  const entity = useMemo(() => {
    if (isVentureIq && profileProvider.data?.data) {
      return {
        ...profileProvider.data.data,
        tags: profileProvider.data.data.systemTags
      }
    } else {
      return profileProvider.data?.data
    }
  }, [isVentureIq, profileProvider.data?.data]);

  const authorize = useAuthorize();
  const snackbar = useSnackbar();

  const updateEvent = useEffectEvent(profileProvider.updaters?.updateData);
  const enrichEvent = useEffectEvent(profileProvider.updaters?.enrichData);
  const updateTagEvent = useEffectEvent(profileProvider.updaters?.updateTag);
  const updateTagGroupEvent = useEffectEvent(profileProvider.updaters?.updateTagGroup);
  const handlePatch = useCallback((entity, field, value, onSuccess, onError) => {
    const promises = [];
    if (utils.camelcase(field.name) === 'tags') {
      promises.push(updateTagEvent?.(entity, entity[!isVentureIq ? 'tags' : 'systemTags'], value));
    } else if (field.tagGroup) {
      promises.push(updateTagGroupEvent?.(entity, field.tagGroup,
        entity.tagGroups.find((tg) => +tg.groupId === +field.tagGroupId)?.tags || [], utils.toArray(value, true)));
    } else if (field.name === 'promise') {
      promises.push(value);
    } else {
      promises.push(updateEvent?.(entity, {[field.name]: value}));
    }

    if (promises.length > 0) {
      Promise.all(promises)
        .then(() => {
          onSuccess?.();
        }).catch((err) => {
          logger.trace('Company save failed', err);
          onError?.();
        });
    } else {
      onSuccess?.();
    }
  }, [updateEvent, updateTagEvent, updateTagGroupEvent, isVentureIq]);

  const handleSubmit = useCallback((entity, values, fields, actions, onSuccess, onError) => {
    let promise;
    const tags = [], tagGroups = [], changes = {};
    Object.keys(values).forEach((k) => {
      const field = fields.find((f) => f.name === k);
      if (field) {
        if (utils.camelcase(field.name) === 'tags') {
          tags.push({field: {...field, name: !isVentureIq ? 'tags' : 'systemTags'}, value: values[k]});
        } else if (field.tagGroup) {
          tagGroups.push({field, value: values[k]});
        } else {
          changes[k] = values[k];
        }
      } else if (k === 'promise') {
        promise = values[k];
      }
    });

    const promises = [];
    tags.forEach((tf) => {
      promises.push(updateTagEvent?.(entity, entity[utils.camelcase(tf.field.name)], tf.value));
    });
    tagGroups.forEach((tf) => {
      promises.push(updateTagGroupEvent?.(entity, tf.field.tagGroup,
        entity.tagGroups.find((tg) => +tg.groupId === +tf.field.tagGroupId)?.tags ?? [],
        utils.toArray(tf.value, true)));
    });
    if (!utils.isEmpty(changes)) {
      promises.push(updateEvent?.(entity, changes));
    }
    if (utils.isDefined(promise)) {
      promises.push(promise);
    }

    if (promises.length > 0) {
      Promise.all(promises)
        .then(() => {
          onSuccess?.();
        }).catch((err) => {
          logger.trace('Company save failed', err);
          onError?.();
        });
    } else {
      onSuccess?.();
    }
  }, [updateEvent, updateTagEvent, updateTagGroupEvent, isVentureIq]);

  const onCanUpdateEvent = useEffectEvent(onCanUpdate);
  const cardsMemo = useMemo(() => {
    if (cardDefinitions) {
      const hasClientPoints = universeCollection?.tagGroups?.some((tg) => tg.hasPoints);
      const hasCollectionPoints = collection?.tagGroups?.some((tg) => tg.hasPoints);

      const entityCards = cardDefinitions.map((cardDef) => {
        const canEditCard = (entity) => {
          const canEditEntity = (onCanUpdateEvent ? onCanUpdateEvent?.(entity) : true) && authorize({
            attribute: 'entity.update', meta: {entity}
          });

          return canEditEntity && !(cardDef.readOnly ?? false) && authorize({...cardDef.auth?.update, meta: {...cardDef.auth?.update?.meta, entity}});
        }

        const fields = cardDef.fields.map((field) => {
          const canEditField = (entity) => {
            return onCanUpdateEvent ? onCanUpdateEvent?.(entity, field) : (field.auth?.update ?
                authorize({...field.auth?.update, meta: {...field.auth?.update?.meta, entity}}) : true);
          }

          return {
            ...field,
            context: (entity) => {
              return {
                ...field.context,
                name: entity?.name,
                legalName: entity?.legalName,
                country: entity?.location?.country,
                city: entity?.city,
                address: entity?.address,
                cocNumber: entity?.cocNumber
              }
            },
            info: (entity, value) => {
              return processEntityInfo(entity, field, value?.value ?? value);
            },
            points: (entity) => {
              const isClient = cardDef.name === 'clientTags';
              const hasPoints = isClient ? hasClientPoints : hasCollectionPoints;
              return (field.tagGroup && hasPoints) ?
                processEntityPoints(entity, field.tagGroup, isClient) : null;
            },
            feedback: (entity, value) => {
              return processEntityFeedback(entity, field, value?.value ?? value)
            },
            editable: ({data}) => canEditField(data)
          }
        });

        const contents = profile.fields2Contents(fields, ['systemInfo', 'finance', 'scoreGraph', 'tractionGraph']);
        contents.forEach((c) => {
          if (c.variant === 'systemInfo') {
            c.Content = ({ref, card, data, content, isLoading}) => {
              return <EntitySystemInfoProfileCardContent ref={ref}
                                                         card={card}
                                                         content={content}
                                                         entity={data}
                                                         isLoading={isLoading}/>
            }
            c.Loader = () => {
              return <Box className="ProfileCardContent-loader">
                <Box className="systemInfo">
                  <Box>
                    <Typography isLoading={true} min={40} max={80}/>
                    <Typography isLoading={true} min={16} max={32}/>
                  </Box>
                  <Typography isLoading={true} style={{
                    minWidth: innerProps.theme.layout('4sp'),
                    minHeight: innerProps.theme.layout('4sp')
                  }}/>
                  <Typography isLoading={true} style={{
                    minWidth: innerProps.theme.layout('4sp'),
                    minHeight: innerProps.theme.layout('4sp')
                  }}/>
                  <Typography isLoading={true} style={{
                    minWidth: innerProps.theme.layout('4sp'),
                    minHeight: innerProps.theme.layout('4sp')
                  }}/>
                </Box>
              </Box>
            }
            c.readOnly = true;
            c.hidden = ({state}) => {
              return Boolean(state?.isEditing);
            }
          } else if (c.variant === 'finance') {
            c.Content = ({ref, profile, card, data, content, fieldData, isDialog, onPatch, onSubmit, onValidating}) => {
              return <EntityFinanceHistoryProfileCardContent ref={ref}
                                                             profile={profile}
                                                             card={card}
                                                             content={content}
                                                             entity={data}
                                                             fieldData={fieldData}
                                                             isDialog={isDialog}
                                                             onPatch={onPatch}
                                                             onSubmit={onSubmit}
                                                             onValidating={onValidating}/>
            }
            c.Loader = () => {
              return <Box className="ProfileCardContent-loader">
                {(new Array(Math.max(6, Math.ceil(Math.random() * 12)))).fill(null)
                  .map((t, idx) => {
                    return <Box key={idx} className="finance">
                      <Box>
                        <Typography isLoading={true} min={8} max={16}/>
                      </Box>
                      <Typography isLoading={true} min={12} max={24}/>
                    </Box>
                  })}
              </Box>
            }
            c.hidden = ({state, data}) => {
              return !Boolean(state?.isEditing || data?.financeHistory?.length > 0);
            }
          } else if (c.variant === 'scoreGraph') {
            const isClient = cardDef.name === 'clientTags';
            c.Content = ({ref, card, data, content, fields, fieldData}) => {
              return <EntityScoreGraphProfileCardContent ref={ref}
                                                         card={card}
                                                         content={content}
                                                         fields={fields}
                                                         entity={data}
                                                         collection={isClient ? universeCollection : collection}
                                                         fieldData={fieldData}/>
            }
            c.Loader = () => {
              return <Box className="ProfileCardContent-loader">
                <Typography isLoading={true}
                            style={{
                              transform: 'unset',
                              minWidth: '100%',
                              minHeight: innerProps.theme.layout('2.5sp')
                            }}/>
              </Box>
            }
            c.hidden = ({state, data}) => {
              const tagCount = data?.tagGroups?.reduce((c, tg) => {
                if ((isClient ? universeCollection : collection)?.tagGroups?.find((tgc) => +tgc.groupId === +tg.groupId)) {
                  return c + (tg.tags?.length ?? 0);
                } else {
                  return c;
                }
              }, 0) ?? 0;

              return Boolean(state?.isEditing) || +tagCount === 0;
            }
          } else if (c.variant === 'tractionGraph') {
            c.Content = ({ref, card, data, content, isLoading}) => {
              return <EntityTractionGraphProfileCardContent ref={ref}
                                                            card={card}
                                                            content={content}
                                                            entity={data}
                                                            isLoading={isLoading}/>
            }
            c.Loader = () => {
              return <Box className="ProfileCardContent-loader">
                <Box>
                  <Typography isLoading={true}
                              style={{
                                minWidth: '100%',
                                minHeight: innerProps.theme.layout('16sp')
                              }}/>
                  <Typography isLoading={true} style={{ minWidth: '100%' }}/>
                </Box>
              </Box>
            }
            c.readOnly = true;
            c.hidden = ({state, data}) => {
              return Boolean(state?.isEditing) || !constants.data.tractionTypes.find((tt) => {
                return (data?.[utils.camelcase(`traction_${tt.value}_history`)] ?? [])
                  .filter((t) => +t.value !== 0).length > 0;
              });
            }
          } else if (c.variant === 'patentGraph') {
            c.Content = ({ref, card, data, content, isLoading}) => {
              return <EntityPatentGraphProfileCardContent ref={ref}
                                                          card={card}
                                                          content={content}
                                                          entity={data}
                                                          isLoading={isLoading}/>
            }
            c.Loader = () => {
              return <Box className="ProfileCardContent-loader">
                <Box>
                  <Typography isLoading={true}
                              style={{
                                minWidth: '100%',
                                minHeight: innerProps.theme.layout('16sp')
                              }}/>
                  <Typography isLoading={true} style={{ minWidth: '100%' }}/>
                </Box>
              </Box>
            }
            c.readOnly = true;
            c.hidden = ({state, data}) => {
              return Boolean(state?.isEditing) || !data?.patentCount > 0;
            }
          } else if (c.variant === 'articles') {
            c.Content = ({ref, card, data, content, isLoading}) => {
              return <EntityArticlesProfileCardContent ref={ref}
                                                       card={card}
                                                       content={content}
                                                       entity={data}
                                                       isLoading={isLoading}/>
            }
            c.Loader = () => {
              return <Box className="ProfileCardContent-loader">
                {(new Array(5)).fill(null)
                  .map((t, idx) => {
                    return <Box key={idx} className="articles">
                      <Box>
                        <Typography isLoading={true}
                                    style={{
                                      minWidth: innerProps.theme.layout('1sp'),
                                      minHeight: innerProps.theme.layout('1sp')
                                    }}/>
                        <Typography isLoading={true} min={24} max={32}/>
                      </Box>
                      <Box>
                        <Typography isLoading={true} min={24} max={48}/>
                      </Box>
                    </Box>
                  })}
              </Box>
            }
            c.readOnly = true;
            c.hidden = ({state, data}) => {
              return Boolean(state?.isEditing) || !data?.articleCount > 0;
            }
          }
        })

        if (contents.length > 0) {
          return {
            ...cardDef,
            editable: ({profile}) => canEditCard(profile.data),
            contents: contents,
            onPatch: handlePatch,
            onSubmit: handleSubmit
          }
        } else {
          return null;
        }
      }).filter((_) => (_));

      return profile.mergeCards(entityCards, cards);
    } else {
      return null;
    }
  }, [cards, cardDefinitions, authorize, collection, universeCollection,
    onCanUpdateEvent, handlePatch, handleSubmit, innerProps.theme]);

  const setSettingsEvent = useEffectEvent(profileProvider.setSettings);
  const enrichingEntity = profileProvider.state?.settings?.enrichingEntity === +entity?.entityId;
  useEffect(() => {
    const enrichmentTime = entity?.enrichmentStats?.enrichment?.updatedAt ? (new Date(entity?.enrichmentStats?.enrichment?.updatedAt)).getTime() : 0;
    const companyInfoTime = entity?.enrichmentStats?.companyInfo?.updatedAt ? (new Date(entity?.enrichmentStats?.companyInfo?.updatedAt)).getTime() : 0;
    const creditSafeTime = entity?.enrichmentStats?.creditSafe?.updatedAt ? (new Date(entity?.enrichmentStats?.creditSafe?.updatedAt)).getTime() : 0;

    const updatedTime = `${enrichmentTime}_${companyInfoTime}_${creditSafeTime}`;
    const canUpdate = (onCanUpdateEvent ? onCanUpdateEvent?.(entity) : true) && authorize({
      attribute: 'entity.enrich', meta: {entity}
    });
    const shouldEnrich = entity?.enrichmentStats?.enrichment?.shouldUpdate ||
      entity?.enrichmentStats?.companyInfo?.shouldUpdate ||
      entity?.enrichmentStats?.creditSafe?.shouldUpdate;

    if (shouldEnrich && canUpdate && !Boolean(activeCard) && entity?.entityId && collection?.collectionId &&
      !enrichingEntity && (+enrichRef.current !== +entity?.entityId || updateRef.current !== updatedTime)) {

      enrichRef.current = +entity?.entityId;
      updateRef.current = updatedTime;
      setSettingsEvent?.({enrichingEntity: +entity?.entityId});

      const updatingSnackbar = snackbar.show('Updating company data...', null, {
        color: 'info',
        autoHideDuration: null
      });

      enrichEvent?.({
        entityId: entity?.entityId,
        collectionId: collection?.collectionId,
        enrichmentTypes: []
          .concat(entity?.enrichmentStats?.enrichment?.shouldUpdate ? ['enrichment'] : [])
          .concat(entity?.enrichmentStats?.companyInfo?.shouldUpdate ? ['companyInfo'] : [])
          .concat(entity?.enrichmentStats?.creditSafe?.shouldUpdate ? ['creditSafe'] : [])
      })
        .then((res) => {
          enrichRef.current = null;
          const entity = utils.camelcase(res.response.data.data);

          if (Boolean(entity?.enrichmentStats?.enrichment?.feedback)) {
            snackbar.show('Verify the company links for more data', null, {
              color: 'success',
              autoHideDuration: constants.delay.long
            });
          }
          if (Boolean(entity?.enrichmentStats?.companyInfo?.feedback)) {
            snackbar.show('Verify the company CoC number for more data', null, {
              color: 'success',
              autoHideDuration: constants.delay.long
            });
          }
          if (Boolean(entity?.enrichmentStats?.creditSafe?.feedback)) {
            snackbar.show('Verify the company Creditsafe ID for more data', null, {
              color: 'success',
              autoHideDuration: constants.delay.long
            });
          }
        })
        .catch(() => {
          snackbar.show('Updating company data failed', null,
            {color: 'error', autoHideDuration: constants.delay.error});
        })
        .finally(() => {
          setSettingsEvent?.({enrichingEntity: null});
          snackbar.hide(updatingSnackbar);
        });
    }
  }, [snackbar, authorize, enrichEvent, collection?.collectionId,
    entity, activeCard, enrichingEntity, onCanUpdateEvent, setSettingsEvent]);

  return <StyledEntityProfile {...innerProps}
                              data={entity}
                              cards={cardsMemo}/>
};

EntityProfile.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  cards: PropTypes.array,
  onCanUpdate: PropTypes.func
};

EntityProfile.defaultProps = {
};

export default EntityProfile;
