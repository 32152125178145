import * as yup from 'yup';
import utils from 'helpers/utils';
import {isValidPhoneNumber} from 'libphonenumber-js'
import constants from 'helpers/constants';
import searchUtils from 'helpers/search';

function file (message, types) {
  return this.test({
    name: 'file',
    message,
    params: { types: utils.toArray(types)?.join(`, `) },
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter((f) => !types || utils.matchFileType(f.name, f.type, utils.toArray(types)))
          .length;
      }
    }
  });
}

function fileSize (message, minSize, maxSize) {
  minSize = minSize ?? 1;
  maxSize = maxSize ?? constants.numbers.GB;

  return this.test({
    name: 'fileSize',
    message,
    params: {minSize: utils.bytesToFileSize(minSize), maxSize: utils.bytesToFileSize(maxSize)},
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter((f) => (!minSize || f.size >= minSize) &&
            (!maxSize || f.size <= maxSize))
          .length;
      }
    }
  });
}

function tag (message) {
  return this.test({
    name: 'tag',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter((t) => {
            return !(/[^A-Za-z0-9_\- ]+/.test(t?.label?.toString?.() ?? t?.value?.toString?.() ?? t))
          })
          .length;
      }
    }
  });
}

function label (message) {
  return this.test({
    name: 'label',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter((t) => {
            return !(/[^A-Za-z0-9_\- ]+/.test(t?.label?.toString?.() ?? t))
          })
          .length;
      }
    }
  });
}

function entity (message) {
  return this.test({
    name: 'entity',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter(() => {
            return true; // no test yet
          })
          .length;
      }
    }
  });
}

function collection (message) {
  return this.test({
    name: 'collection',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter(() => {
            return true; // no test yet
          })
          .length;
      }
    }
  });
}

function dealLeader (message) {
  return this.test({
    name: 'dealLeader',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter(() => {
            return true; // no test yet
          })
          .length;
      }
    }
  });
}

function dealflowStatus (message) {
  return this.test({
    name: 'dealflowStatus',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter(() => {
            return true; // no test yet
          })
          .length;
      }
    }
  });
}

function status (message) {
  return this.test({
    name: 'status',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter(() => {
            return true; // no test yet
          })
          .length;
      }
    }
  });
}

function field (message) {
  return this.test({
    name: 'field',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter(() => {
            return true; // no test yet
          })
          .length;
      }
    }
  });
}

function team (message) {
  return this.test({
    name: 'team',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter(() => {
            return true; // no test yet
          })
          .length;
      }
    }
  });
}

function listObject (message) {
  return this.test({
    name: 'listObject',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter(() => {
            return true; // no test yet
          })
          .length;
      }
    }
  });
}

function fieldMapping (message) {
  return this.test({
    name: 'fieldMapping',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter(() => {
            return true; // no test yet
          })
          .length;
      }
    }
  });
}

function listMapping (message) {
  return this.test({
    name: 'listMapping',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        return value.length === value
          .filter(() => {
            return true; // no test yet
          })
          .length;
      }
    }
  });
}

function component (message) {
  return this.test({
    name: 'component',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        return !utils.isObject(value) || !value.errors;
      }
    }
  });
}

function query (message, required) {
  return this.test({
    name: 'query',
    message,
    params: { required },
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return !required;
      } else {
        return Boolean(value?.id && value?.name) && (required ? searchUtils.queryFilterCount(value) > 0 : true);
      }
    }
  });
}

function mapping (message, required) {
  return this.test({
    name: 'mapping',
    message,
    params: { required },
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return !required;
      } else {
        return required ? (utils.isDefined(value?.team?.teamId) &&  value.lists?.length > 0) : true;
      }
    }
  });
}

function list (message, type) {
  return this.test({
    name: 'list',
    message,
    params: { type },
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        return utils.isArray(value);
      }
    }
  });
}

function unique (message, type) {
  return this.test({
    name: 'unique',
    message,
    params: { type },
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        value = utils.toArray(value);

        if ([constants.formFieldValidationTypes.tag, constants.formFieldValidationTypes.status].includes(type)) {
          return value.length === utils.uniqueArray(value).length &&
            value.length === utils.uniqueArray(value.map((v) => v?.value?.toString().toLowerCase()), null, true).length &&
            value.length === utils.uniqueArray(value.map((v) => v?.label?.toString().toLowerCase()), null, true).length;
        } else if ([constants.formFieldValidationTypes.mapping].includes(type)) {
          return value.length === utils.uniqueArray(value).length &&
            value.length === utils.uniqueArray(value, null, true, (a, b) => {
              return a.teams?.[0]?.from?.id === b.teams?.[0]?.from?.id &&
                a.teams?.[0]?.to?.id === b.teams?.[0]?.to?.id;
            }).length;
        } else if ([constants.formFieldValidationTypes.teamMapping, constants.formFieldValidationTypes.listMapping, constants.formFieldValidationTypes.fieldMapping].includes(type)) {
          return value.length === utils.uniqueArray(value).length &&
            value.length === utils.uniqueArray(value, null, true, (a, b) => {
              return a.from?.id === b.from?.id &&
                a.to?.id === b.to?.id;
            }).length;
        } else {
          return value.length === utils.uniqueArray(value).length;
        }
      }
    }
  });
}

function truthy (message) {
  return this.test({
    name: 'truthy',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        return value === true;
      }
    }
  });
}

function country (message) {
  return this.test({
    name: 'country',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        return Boolean(constants.data.lookup('countries', value));
      }
    }
  });
}

function phone (message, country) {
  return this.test({
    name: 'phone',
    message,
    params: { country: constants.data.lookup('countries', country?.toUpperCase())?.name ?? country },
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        return isValidPhoneNumber(value, country);
      }
    }
  });
}

function username (message) {
  return this.test({
    name: 'username',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        return !(/[^A-Za-z0-9_]+/.test(value?.toString?.()));
      }
    }
  });
}

function number (message) {
  return this.test({
    name: 'number',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        return utils.isNumber(value);
      }
    }
  });
}

function year (message) {
  return this.test({
    name: 'year',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        const d = new Date(`${utils.fillYear(value)}-01-01T00:00:00.000Z`);
        return utils.fillYear(value).toString().length === 4 && utils.isDate(d);
      }
    }
  });
}

function hostname (message) {
  return this.test({
    name: 'hostname',
    message,
    test: function (value) {
      if (!utils.isDefined(value) || value === '') {
        return true;
      } else {
        return Boolean(utils.getHostname(value));
      }
    }
  });
}

// array
yup.addMethod(yup.mixed, 'file', file);
yup.addMethod(yup.mixed, 'fileSize', fileSize);
yup.addMethod(yup.mixed, 'year', year);
yup.addMethod(yup.mixed, 'tag', tag);
yup.addMethod(yup.mixed, 'label', label);
yup.addMethod(yup.mixed, 'entity', entity);
yup.addMethod(yup.mixed, 'collection', collection);
yup.addMethod(yup.mixed, 'dealLeader', dealLeader);
yup.addMethod(yup.mixed, 'dealflowStatus', dealflowStatus);
yup.addMethod(yup.mixed, 'status', status);
yup.addMethod(yup.mixed, 'field', field);
yup.addMethod(yup.mixed, 'team', team);
yup.addMethod(yup.mixed, 'listObject', listObject);
yup.addMethod(yup.mixed, 'fieldMapping', fieldMapping);
yup.addMethod(yup.mixed, 'listMapping', listMapping);
yup.addMethod(yup.mixed, 'query', query);
yup.addMethod(yup.mixed, 'mapping', mapping);

yup.addMethod(yup.array, 'list', list);
yup.addMethod(yup.array, 'unique', unique);
yup.addMethod(yup.object, 'component', component);

yup.addMethod(yup.bool, 'truthy', truthy);

yup.addMethod(yup.string, 'country', country);
yup.addMethod(yup.string, 'phone', phone);
yup.addMethod(yup.string, 'username', username);
yup.addMethod(yup.string, 'number', number);
yup.addMethod(yup.string, 'hostname', hostname);

const validation = yup;

export default validation;
