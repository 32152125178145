import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import AccordionSummary from 'components/atoms/Accordions/AccordionSummary/AccordionSummary';
import Icon from 'components/atoms/Icons/Icon/Icon';
import {ArrowDropDown} from '@mui/icons-material';
import AccordionDetails from 'components/atoms/Accordions/AccordionDetails/AccordionDetails';
import Typography, {Span} from 'components/atoms/Text/Typography/Typography';
import Component from 'components/organisms/Utils/Component/Component';
import Badge from 'components/atoms/Badges/Badge/Badge';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import StyledKanbanPanelContent from 'components/organisms/Kanbans/KanbanPanelContent/KanbanPanelContent.styles';
import IconButton from 'components/atoms/Buttons/IconButton/IconButton';
import {useDroppable} from '@dnd-kit/core';
import DataList from 'components/organisms/Lists/DataList/DataList';

const KanbanPanelContent = React.forwardRef((props, ref) => {
  const {
    kanban,
    panel,
    content,
    list,
    dataKey,
    title,
    color,
    onClick,
    onChange,
    onCanUpdate,
    disableReorder,
    isLoading,
    CardProps,
    ...innerProps
  } = useComponentProps({...props, ...utils.filterObject(props.content, [
      'title', 'color', 'CardProps'
    ], false), ...props.content?.KanbanPanelContentProps}, 'KanbanPanelContent');

  const initialised = useRef(null);
  const innerRef = useRef(null);
  const listRef = useRef(null);

  const [internalState, setInternalState] = useState({
    expanded: false,
    count: 0
  });

  const { setNodeRef } = useDroppable({id: `${content.id}`});

  const isContentLoading = list?.status?.isLoading || !list?.status?.isSuccess;
  const isContentReloading = list?.status?.isReloading;

  const kanbanPanelContent = useMemo(() => ({
    refs: {
      ref: innerRef,
      listRef
    },
    state: internalState,
    reset: () => {
      listRef.current?.refs?.listRef?.current?.reset();
    }
  }), [internalState]);

  useImperativeHandle(ref, () => kanbanPanelContent);

  useEffect(() => {
    if (isContentLoading) {
      initialised.current = null;
      setInternalState(utils.updater({expanded: false}, true));
    } else if (utils.isDefined(list?.meta?.resultsCount)) {
      if (initialised.current !== +list?.meta?.resultsCount) {
        initialised.current = +list?.meta?.resultsCount;
        setInternalState(utils.updater({expanded: list?.meta?.resultsCount > 0}, true));
      }
    }
  }, [isContentLoading, list?.meta?.resultsCount]);

  useEffect(() => {
    setInternalState(utils.updater({
      count: list?.meta?.resultsCount
    }, true));
  }, [list?.meta?.resultsCount]);

  useEffect(() => {
    if (!isContentReloading) {
      listRef.current?.refs?.listRef?.current?.reset();
      if (list?.meta?.resultsCount === 0) {
        setInternalState(utils.updater({
          count: 0,
          expanded: false
        }, true));
      }
    }
  }, [list?.meta?.resultsCount, isContentReloading]);

  // detect change to firstItem scroll to top
  const firstItemId = kanbanPanelContent.state.items?.[0]?.id;
  useEffect(() => {
    listRef.current?.querySelector?.('.DataList-list')?.scrollTo?.({
      top: 0,
      left: 0
    });
  }, [firstItemId]);

  const renderBadge = () => {
    return <Badge isLoading={isContentLoading} badgeContent={<Span>{kanbanPanelContent.state.count ?? 0}</Span>} />;
  }

  const renderTitle = () => {
    return title ? <Box className="KanbanPanelContent-title">{utils.isReactElement(title) ? title :
      <Typography variant="subtitle1" color={color}
                  isLoading={isContentLoading}
                  min={8} max={20}>{title}</Typography>}</Box> : null;
  }

  const renderCard = (item, state, overlay = false) => {
    const draggable = (onCanUpdate ? onCanUpdate?.(item) : true);
    return <Component Original={content.Card}
                      key={item?.[dataKey]}
                      item={item}
                      onClick={onClick}
                      dragHandle={draggable}
                      dragging={overlay}
                      fullWidth={true}
                      selected={state?.selected}
                      isLoading={state?.isLoading}
                      {...CardProps} />
  }

  const renderItem = (item, state) => {
    return renderCard(item, state, false);
  }

  const handleCanDrag = (id) => {
    const item = list?.data?.find((d) => d[dataKey].toString() === id?.toString());
    if (item) {
      return (onCanUpdate ? onCanUpdate?.(item) : true) && item?.draggable !== false;
    } else {
      return false;
    }
  }

  const renderOverlay = (dragItem) => {
    return renderCard(dragItem.data?.current?.payload?.props?.children?.props?.item, null, true);
  }

  const handleDragOver = (active, added, removed) => {
    if (active) {
      const count = list?.meta?.resultsCount +
        (added.filter((id) => !list?.data?.find((d) => id.toString() === d[dataKey].toString())).length) -
        (list?.data?.filter((d) => removed.find((id) => id.toString() === d[dataKey].toString())).length);

      setInternalState(utils.updater({
        count,
        expanded: count > 0
      }, true));
    }
  }

  const handleDragDrop = (id, containerId, position) => {
    const item = list?.data?.find((d) => d[dataKey].toString() === id?.toString());
    if (item) {
      onChange?.(item, containerId, position);
    }
  }

  const renderContent = () => {
    const renderedContent = <DataList ref={listRef}
                                      data={list?.data}
                                      className="KanbanPanelContent-content"
                                      dataKey={kanban.dataKey}
                                      isItemEqual={kanban.isItemEqual}
                                      renderItem={renderItem}
                                      count={kanbanPanelContent.state.count ?? 0}
                                      onFetchMore={list?.query?.fetchNextPage}
                                      isLoading={isContentLoading}
                                      loaderCount={8}
                                      showProgressBar={list?.status?.isLoadingNext}
                                      emptyText={content.emptyText}
                                      ListProps={{
                                        track: true,
                                        virtualize: true,
                                        sortable: true,
                                        containerId: content.id,
                                        reorder: false,
                                        dragHandle: true,
                                        gap: 4,
                                        renderOverlay: renderOverlay,
                                        onCanDrag: handleCanDrag,
                                        onDragOver: handleDragOver,
                                        onDragDrop: handleDragDrop
                                      }}/>
    if (content.Content) {
      return <Component Original={content.Content}
                        ref={listRef}
                        className="KanbanPanelContent-content"
                        list={list}
                        kanban={kanban}
                        panel={panel}
                        content={content}
                        isLoading={isContentLoading}
                        renderedContent={renderedContent} />
    } else {
      return renderedContent;
    }
  }

  const handleExpand = (e, expanded) => {
    setInternalState(utils.updater({expanded}, true));
  }

  const handleToggle = () => {
    setInternalState((current) => ({
      ...current,
      expanded: !current.expanded
    }));
  }

  const handleRef = (el) => {
    setNodeRef(el);
    innerRef.current = el;
  }

  return <StyledKanbanPanelContent ref={handleRef} {...innerProps}
                                   expanded={kanbanPanelContent.state.expanded}
                                   onChange={handleExpand}
                                   elevation={0}
                                   density="dense"
                                   square={true}
                                   disableGutters={true}>
    <AccordionSummary badge={renderBadge()}
                      expandIcon={<IconButton density="dense"
                                              size="smaller"
                                              variant="outlined"
                                              onClick={handleToggle}>
                        <Icon size="tiny" icon={ArrowDropDown} />
                      </IconButton>}>
      {renderTitle()}
    </AccordionSummary>
    <AccordionDetails>
      {renderContent()}
    </AccordionDetails>
  </StyledKanbanPanelContent>
});

KanbanPanelContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  kanban: PropTypes.object,
  panel: PropTypes.object,
  content: PropTypes.object,
  dataKey: PropTypes.string,
  title: PropTypes.any,
  color: PropTypes.string,
  list: PropTypes.object,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onCanUpdate: PropTypes.func,
  disableReorder: PropTypes.bool,
  isLoading: PropTypes.bool,
  CardProps: PropTypes.object
};

KanbanPanelContent.defaultProps = {
};

export default KanbanPanelContent;
