import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledArticleCard = styled(Card)`
  display: flex;
  flex-direction: column;
  
  .ArticleCard {
    &-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('1sp')};

      padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(1.5)} ${props => props.theme.spacing(2)};
    }

    &-title {
      display: flex;
      align-items: flex-start;
      gap: ${props => props.theme.layout('0.75sp')};
      
      > *:last-child {
        flex-grow: 1;
        max-width: unset;
        display: inline-block;
        overflow: hidden;

        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    
    &-logo {
      padding-top: ${props => props.theme.layout(1.5)};
      min-width: ${props => props.theme.layout(16)};
      max-width: ${props => props.theme.layout(16)};
      
      img {
        width: ${props => props.theme.layout(14)};
        height: ${props => props.theme.layout(14)};
        margin-top: ${props => props.theme.layout(1)};
        margin-left: ${props => props.theme.layout(1)};
      }
    }
    
    &-labels {
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
    
    &-text {
      width: 100%;
      overflow: hidden;
      
      &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        
        .readMore {
          ${props => props.theme.font2Css('caption')};
          position: absolute;
          bottom: ${props => props.theme.layout(14)};
        }
      }
    }
    
    &-footer {
      display: flex;
      align-items: center;
      padding: 0 ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)};
      gap: ${props => props.theme.layout('1sp')};
      overflow: hidden;

      .ArticleCard-date, .ArticleCard-author {
        line-height: ${props => props.theme.lineHeight('1f')};
        align-items: center;
        color: ${props => props.theme.property('palette.text.disabled')};
        
        margin-top: ${props => props.theme.layout(0)};
        svg {
          margin-top: ${props => props.theme.layout(-1)};
        }

        &.Typography {
          display: flex;
          gap: ${props => props.theme.layout('0.5sp')};

          > * {
            white-space: nowrap;
          }
        }
      }

      .ArticleCard-author {
        &.Typography {
          overflow: hidden;
          > * {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  
  &:has(.readMore) {
    .ArticleCard-footer {
      padding-right: ${props => props.theme.layout(92)};
    }
  }

  &.ArticleCard-clampText {
    .ArticleCard-content {
      overflow: hidden !important;
      .ArticleCard-text {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledArticleCard.propTypes = {
  theme: PropTypes.object
};

StyledArticleCard.defaultProps = {}

export default StyledArticleCard;
