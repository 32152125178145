import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledCollectionTagGroupProfileCardContent = styled(Box)`
  &.CollectionTagGroupProfileCardContent  {
    display: flex;
    gap: ${props => props.theme.layout('1sp')};
    width: 100%;
    
    .CollectionTagGroupProfileCardContent  {
      &-content {
        display: flex;
        flex-direction: row;
        column-gap: ${props => props.theme.layout('1sp')};
        width: 100%;

        .TagGroupCard {
          flex-grow: 1;
          width: 1px;
        }
      }
    }
  }

  &:not(.CollectionTagGroupProfileCardContent-isEditing) {
    &.CollectionTagGroupProfileCardContent-isAddNew {
      width: 100%;
      height: 100%;
      padding: 0;
      min-height: ${props => props.theme.layout(272)};
      max-height: ${props => props.theme.layout(272)};
      background-color: ${props => props.theme.property(`palette.primary.states.focus`)};

      .CollectionTagGroupProfileCardContent-content {
        width: 100%;
        height: 100%;

        .Card {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
        }
      }
    }
  }

  &.CollectionTagGroupProfileCardContent-isEditing  {
    .CollectionTagGroupProfileCardContent {
      &-form {
        display: flex;
        flex-direction: column;
        
        .DialogHeader {
          &-title {
            overflow: visible;
          }
          
          .FormField-name-name {
            flex-grow: 1;
            max-width: ${props => props.theme.layout(356)};

            .MuiFormHelperText-root:has(*) {
              display: flex;
              position: absolute;
              width: 100%;
              bottom: ${props => props.theme.layout(-14)};
              height: ${props => props.theme.layout(16)};

              > * {
                width: 1px;
                flex-grow: 1;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                p {
                  display: contents;
                }
              }
            }
          }
        }
        
        .DialogContent {
          flex-grow: 1;
          height: 1px;
          overflow: hidden;

          .ContextWrapper-content {
            flex-grow: 1;
            width: 1px;
          }
        }
        
        &-content {
          height: 100%;  
          overflow-y: auto;
          row-gap: ${props => props.theme.layout('1sp')} !important;
          align-content: flex-start;

          padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
          background-color: ${props => props.theme.property('palette.primary.states.hover')};
          
          .FormControl {
            .MuiInputBase-root {
              background-color: ${props => props.theme.property('palette.background.paper')};
            }
          }

          .FormField-name-tags {
            > .InputLabel, > .FormLabel {
              ${props => props.theme.font2Css('h6')};
              margin-bottom: calc(${props => props.theme.spacing(1)} - 1px);
            }
            
            .InputContainer {
              .List {
                overflow-x: auto;
                
                .ListItem {
                  min-width: 100%;
                  width: fit-content;
                }
              }
            }
          }
        }
        
        &-context {
          height: 100%;
          overflow-y: auto;
          padding: ${props => props.theme.spacing(1)} 0;
            
          .Form-fields {
            min-height: 100%;
            flex-direction: column;
            flex-wrap: nowrap;
            padding: 0 ${props => props.theme.spacing(2)};
            row-gap: ${props => props.theme.layout('0.5sp')};
          
            .Form-section {
              margin-bottom: ${props => props.theme.spacing(0.5)};
            }
            
            .FormControl-inlineLabel {
              .InputLabel, .FormLabel {
                ${props => props.theme.font2Css('subtitle2')};
              }
            }

            .FormField-name-color {
              margin-top: ${props => props.theme.spacing(0.5)};
            }

            .FormField-name-hasPoints {
              margin-top: ${props => props.theme.spacing(0.5)};
            }
            
            .FormField-name-multiselect {
              margin-top: -${props => props.theme.spacing(0.5)};
            }

            .FormField-name-hasPoints, .FormField-name-multiselect {
              .SwitchField-label {
                margin-right: -${props => props.theme.layout(6)};
              }
            }

            .FormField-name-hasPoints, .FormField-name-multiselect, .FormField-name-color {
              justify-content: space-between;

              .InputContainer {
                flex-grow: unset;
              }

              .InputLabel {
                white-space: nowrap;
                max-width: unset;
                min-width: ${props => props.theme.layout(80)};
              }
            }

            .FormField-name-autoTagType {
              flex-grow: 1;
              height: 1px;
              overflow: hidden;
              min-height: ${props => props.theme.layout('28sp')};
              margin-top: ${props => props.theme.spacing(1.5)};
              gap: ${props => props.theme.layout('0.5sp')};
              
              .FormLabel {
                > .Box {
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                }
              }
              
              .ListField-container { 
                overflow-y: auto;
              }
            }
          }
        }
      }
    }
  }
    
  ${ComponentStyles}
`;

StyledCollectionTagGroupProfileCardContent.propTypes = {
  theme: PropTypes.object
};

StyledCollectionTagGroupProfileCardContent.defaultProps = {}

export default StyledCollectionTagGroupProfileCardContent;
